import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// Styles
import { linkColor } from "../components/styles/colors";

// Components
import { PageTitleConsumer } from "../components/context/page-title-context";
import { ProjectsActiveFilterConsumer } from "../components/context/projects-active-filter";
import ProjectPage from "../components/project/project-page";
import Line from "../components/misc/line";

const Page = styled.div`
  padding: 36px 20px 0 20px;

  @media (max-width: 1160px) {
    padding: 65px 20px 0 20px;
  }

  @media (max-width: 767px) {
    padding: 36px 10px 0 10px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const NextPrevProjects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  & div {
    padding: 12px 0 9px 0;
  }

  & a {
    width: 50%;

    line-height: 1;

    text-align: center;

    &:nth-of-type(3) {
      display: none;
    }

    &:first-of-type {
      border-right: 1px solid #000;
    }

    &:hover {
      color: ${linkColor};
    }

    & h3 {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      &:first-of-type,
      &:nth-of-type(2) {
        display: none;
      }

      &:nth-of-type(3) {
        width: auto;
        display: block;
        text-align: left;
      }
    }

    @media (max-width: 500px) {
      width: 100%;

      line-height: 21px;
    }
  }

  @media (max-width: 767px) {
    display: none;
    order: 12;
  }
`;

const MobileNextPrevProjects = styled.div`
  display: none;

  width: 100%;

  & a {
    width: 50%;

    line-height: 1;
    padding: 12px 0 9px 0;

    text-align: center;

    & h3 {
      font-size: 15px;
    }

    & strong {
      margin-top: -3px;
      display: inline-block;
    }

    @media (max-width: 767px) {
      width: auto;
      display: block;
      text-align: left;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    order: 12;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Project = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const seoPageTitle = data.prismicProject.data.title.text
    ? `${data.prismicProject.data.title.text} – Wolfe Hall`
    : "Wolfe Hall";

  const findTextDescription = data.prismicProject.data.body1
    .filter(content => content !== null && content !== undefined)
    .filter(content => content.slice_type === "text");

  const seoDescription =
    findTextDescription.length >= 1 &&
    `${findTextDescription[0].primary.text.text.substring(0, 99)}...`;

  const nextLink = data.prismicProject.data.next_link;
  const previousLink = data.prismicProject.data.previous_link;

  return (
    <ProjectsActiveFilterConsumer>
      {({ setProjectsActiveFilter }) => {
        return (
          <PageTitleConsumer>
            {({ setPageTitle }) => (
              <Page>
                <Helmet
                  title={seoPageTitle}
                  meta={[
                    {
                      name: "description",
                      content: `${seoDescription}`,
                    },
                    {
                      name: "og:title",
                      content: `${seoPageTitle}`,
                    },
                    {
                      name: "og:url",
                      content: `https://www.wolfehall.com${data.prismicProject.url}`,
                    },
                    {
                      name: "og:description",
                      content: `${seoDescription}`,
                    },
                    {
                      name: "twitter:description",
                      content: `${seoDescription}`,
                    },
                  ]}
                />
                <ProjectPage
                  data={data.prismicProject.data}
                  setPageTitle={setPageTitle}
                  title={data.prismicProject.data.title}
                  tags={data.prismicProject.tags}
                  client={data.prismicProject.data.client}
                  setProjectsActiveFilter={setProjectsActiveFilter}
                />

                <Line
                  mobileWidth={`100vw`}
                  margin={`30px -20px 0 -20px`}
                  mobileMargin={`0 -10px`}
                  order={12}
                />
                <NextPrevProjects>
                  {previousLink.document !== null && (
                    <Link to={previousLink.document.url}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: previousLink.document.data.title.html,
                        }}
                      />
                    </Link>
                  )}
                  {nextLink.document !== null && (
                    <Link to={nextLink.document.url}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: nextLink.document.data.title.html,
                        }}
                      />
                    </Link>
                  )}
                </NextPrevProjects>

                <MobileNextPrevProjects>
                  <Link to={`/projects`}>
                    <strong>←</strong> Back to Projects
                  </Link>
                </MobileNextPrevProjects>
              </Page>
            )}
          </PageTitleConsumer>
        );
      }}
    </ProjectsActiveFilterConsumer>
  );
};

export const query = graphql`
  query Projects($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      prismicId
      tags
      url
      data {
        title {
          html
          text
        }
        client
        project_year
        body {
          ... on PrismicProjectBodyImage {
            slice_type
            primary {
              image_caption {
                html
                text
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
            id
          }
          ... on PrismicProjectBodyFullBleedGallery {
            slice_type
            primary {
              image_caption {
                html
                text
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
            id
          }
          ... on PrismicProjectBodyGalleryWithBackgroundImage {
            id
            slice_type
            primary {
              image_caption {
                html
                text
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              background_image {
                url
                alt
              }
            }
          }
          ... on PrismicProjectBodyBgColorGallery {
            id
            slice_type
            primary {
              background_color
              image_caption {
                html
                text
              }
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProjectBodyImageWithVideoBg {
            id
            slice_type
            primary {
              image_caption {
                html
                text
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              video {
                url
              }
            }
          }
          ... on PrismicProjectBodyFullBleedVideo {
            id
            slice_type
            primary {
              video {
                url
              }
              image_caption {
                html
              }
            }
          }
          ... on PrismicProjectBodyVideo {
            id
            slice_type
            primary {
              padding
              video {
                url
              }
              image_caption {
                html
              }
            }
          }
        }
        body1 {
          ... on PrismicProjectBody1Gallery {
            id
            slice_type
            items {
              image_caption {
                html
                text
              }
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProjectBody1LineBreak {
            id
            slice_type
          }
          ... on PrismicProjectBody1ContextualImages {
            id
            slice_type
            items {
              image_caption {
                html
                text
              }
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProjectBody1Text {
            id
            slice_type
            primary {
              text {
                html
                text
              }
            }
            items {
              link {
                html
              }
            }
          }
          ... on PrismicProjectBody1Video {
            id
            slice_type
            primary {
              video {
                html
                embed_url
              }
              video_caption {
                html
              }
            }
          }
          ... on PrismicProjectBody1Credits {
            id
            slice_type
            items {
              credit_text {
                html
              }
              credit_title {
                html
              }
            }
            primary {
              display_categories
              display_client
            }
          }
          ... on PrismicProjectBody1Footnotes {
            id
            slice_type
            primary {
              footnotes {
                html
              }
            }
          }
        }
        next_link {
          document {
            ... on PrismicResearch {
              uid
              url
              prismicId
              data {
                title {
                  html
                }
              }
              type
            }
            ... on PrismicProject {
              uid
              url
              prismicId
              data {
                title {
                  html
                }
              }
              type
            }
          }
        }
        previous_link {
          document {
            ... on PrismicProject {
              uid
              url
              type
              prismicId
              data {
                title {
                  html
                }
              }
            }
            ... on PrismicResearch {
              uid
              url
              type
              prismicId
              data {
                title {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
