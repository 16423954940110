import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Components
import ProjectGallery from "../images/project-gallery";
import Title from "../text/title";
import AdditionalContent from "./additional-content";

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 73px);
`;

const ProjectPage = ({
  data,
  setPageTitle,
  setProjectsActiveFilter,
  title,
  tags,
  client,
}) => {
  useEffect(() => {
    setPageTitle(title);
  }, []);

  const categories = tags.map((tag, index) => (
    <span key={`tag_${index}`}>
      <Link to={`/projects`} onClick={() => setProjectsActiveFilter(tag)}>
        {tag}
      </Link>
    </span>
  ));

  return (
    <Container>
      <Title
        text={title}
        margin={`40px 0`}
        // mobileMargin={``}
        showDesktop={`none`}
        showMobile={`block`}
        mobileMargin={`30px 0`}
      />

      <ProjectGallery images={data.body} />

      {data.body1.length >= 1 && (
        <AdditionalContent
          data={data.body1}
          categories={categories}
          client={client}
        />
      )}
    </Container>
  );
};

export default ProjectPage;
