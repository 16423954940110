import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import "../styles/plyr.css";

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 60px auto;
  padding: 0;
`;

const Thumbnail = styled.img`
  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const Caption = styled.div`
  width: 100%;
  // max-width: calc(100vh - 100px);
  max-width: 1000px;

  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-height: 850px) {
    max-width: 1200px;
  }

  & p:empty {
    display: none;
  }

  & .caption-text {
    max-width: calc(100% - 60px);
    display: block;
    position: relative;

    text-align: center;
  }

  & p {
    margin: 0;
    padding: 10px 0 0 0;

    & span {
      font-size: 15px;
    }
  }

  & p.spacer {
    display: block;
    width: 30px;
  }
`;

// async function getVimeoThumbnail(video) {
//   let response = await fetch(
//     `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${video}&width=1920&height=1080&quality=1080`
//   );
//   let data = await response.json();
//   return data;
// }

export const Video = ({ video, embed_url, playerId, caption }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#plyr_id_${playerId}`, {
        autoplay: true,
        muted: true,
        loop: { active: true },
        controls: ["play", "fullscreen"],
        hideControls: false,
      });
    }
  }, []);

  return (
    <VideoWrapper className="video">
      {/* {isThumbnailVisible && <Thumbnail src={vimeoThumbnail} loading="lazy" />} */}
      <div
        className="plyr__video-embed"
        id={`plyr_id_${playerId}`}
        dangerouslySetInnerHTML={{
          __html: video.html,
        }}
      />
      <Caption justifyContent={"center"}>
        <div
          className="caption-text"
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        />
      </Caption>
    </VideoWrapper>
  );
};
