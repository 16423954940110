import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

// Check is mobile
import isMobile from "ismobilejs";

// Components
import ThreeTwo from "../images/three-two";

const GalleryContainer = styled.div`
  position: relative;
  order: 2;

  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  padding: 30px 0 20px 0;

  & .image-gallery {
    overflow: hidden;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  @media (min-height: 850px) {
    max-width: 1200px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-width: 1000px;
  margin: 0 auto;

  & img {
    object-fit: contain;
    padding: 0;
  }

  @media (min-height: 850px) {
    max-width: 1200px;
  }
`;

const SingleImageWrapper = styled.div``;

const Caption = styled.div`
  width: 100%;
  // max-width: calc(100vh - 100px);
  max-width: 1000px;

  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};

  @media (min-height: 850px) {
    max-width: 1200px;
  }

  & p:empty {
    display: none;
  }

  & .caption-text {
    max-width: calc(100% - 60px);
    display: block;
    position: relative;

    text-align: center;
  }

  & p {
    margin: 0;
    padding: 10px 0 0 0;

    & span {
      font-size: 15px;
    }
  }

  & p.spacer {
    display: block;
    width: 30px;
  }
`;

const VideoContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  & video {
    width: auto;
    height: auto;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    z-index: 1;

    padding: ${props => props.padding}px;
  }
`;

const Video = styled.video`
  width: auto;
  height: auto;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  object-fit: cover;
  object-position: center;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  z-index: 1;
`;

class ProjectGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allImages: [],
      activeSlide: null,
      totalSlides: 1,
      activeSlideCaption: null,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.images.length;
    const isMobileDevice = isMobile(window.navigator.userAgent).any ? 150 : 0;

    this.setState(
      {
        allImages: this.props.images,
        totalSlides: slideTotal,
        transitionTime: isMobileDevice,
      },
      () => {
        let activeSlideCaption = this.state.allImages[this.state.currentSlide]
          .primary.image_caption.html;

        this.setState({
          activeSlideCaption: activeSlideCaption,
        });
      }
    );
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(
        {
          currentSlide: index,
        },
        () => {
          let activeSlideCaption = this.state.allImages[this.state.currentSlide]
            .primary.image_caption.html;

          this.setState({
            activeSlideCaption: activeSlideCaption,
          });
        }
      );
    } else {
      let activeSlideCaption = this.state.allImages[this.state.currentSlide]
        .primary.image_caption.html;

      this.setState({
        activeSlideCaption: activeSlideCaption,
      });
    }
  };

  render() {
    const galleryImages = this.props.images.map((image, index) => {
      if (image.slice_type === "image") {
        return (
          <ImageContainer key={index} className="image">
            <ThreeTwo
              image={image.primary.image.url}
              alt={image.primary.image.alt}
              lazy={false}
              size={800}
              objectFit={
                image.primary.image.dimensions.width >
                image.primary.image.dimensions.height
                  ? `cover`
                  : `contain`
              }
            />
          </ImageContainer>
        );
      }

      if (image.slice_type === "full_bleed_gallery") {
        return (
          <ImageContainer key={index} className="image">
            <ThreeTwo
              image={image.primary.image.url}
              alt={image.primary.image.alt}
              lazy={false}
              size={800}
              objectFit={
                image.primary.image.dimensions.width >
                image.primary.image.dimensions.height
                  ? `cover`
                  : `contain`
              }
            />
          </ImageContainer>
        );
      }

      if (image.slice_type === "bg_color_gallery") {
        return (
          <ImageContainer key={index}>
            <ThreeTwo
              image={image.primary.image.url}
              alt={image.primary.image.alt}
              lazy={false}
              size={800}
              backgroundColor={image.primary.background_color}
              padding={`20px 0`}
            />
          </ImageContainer>
        );
      }

      if (image.slice_type === "gallery_with_background_image") {
        return (
          <ImageContainer key={index}>
            <ThreeTwo
              image={image.primary.image.url}
              alt={image.primary.image.alt}
              lazy={false}
              size={800}
              backgroundImage={image.primary.background_image.url}
              padding={`20px 0`}
            />
          </ImageContainer>
        );
      }

      if (image.slice_type === "image_with_video_bg") {
        return (
          <ImageContainer key={index}>
            <ThreeTwo
              image={image.primary.image.url}
              alt={image.primary.image.alt}
              lazy={false}
              size={800}
              padding={`20px 0`}
              video={image.primary.video.url}
            />
          </ImageContainer>
        );
      }

      if (image.slice_type === "video") {
        return (
          <VideoContainer
            key={`homepage_gallery_${index}`}
            className="video"
            padding={image.primary.padding}
          >
            {image.primary.video.url && (
              <video loop autoPlay muted playsInline preload="none">
                <source type="video/mp4" src={image.primary.video.url} />
              </video>
            )}
          </VideoContainer>
        );
      }

      if (image.slice_type === "full_bleed_video") {
        return (
          <ImageContainer
            key={`homepage_gallery_${index}`}
            className="full-bleed-video"
          >
            {image.primary.video.url && (
              <Video loop autoPlay muted playsInline preload="none">
                <source type="video/mp4" src={image.primary.video.url} />
              </Video>
            )}
          </ImageContainer>
        );
      }
    });

    if (galleryImages.length > 1) {
      return (
        <GalleryContainer>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            className="image-gallery"
            selectedItem={this.state.currentSlide}
            onChange={this.updateCurrentSlide}
            transitionTime={this.state.transitionTime}
          >
            {galleryImages}
          </Carousel>

          <Caption
            justifyContent={
              this.state.totalSlides > 1 ? "space-between" : "center"
            }
          >
            {this.state.totalSlides > 1 && (
              <p className="spacer">
                <span>{`${this.state.currentSlide + 1}/${
                  this.state.totalSlides
                }`}</span>
              </p>
            )}

            <div
              className="caption-text"
              dangerouslySetInnerHTML={{
                __html: this.state.activeSlideCaption,
              }}
            />

            {this.state.totalSlides > 1 && <p className="spacer"></p>}
          </Caption>
        </GalleryContainer>
      );
    } else {
      return (
        <GalleryContainer>
          <SingleImageWrapper>{galleryImages}</SingleImageWrapper>

          {this.state.activeSlideCaption !== "" && (
            <Caption justifyContent={"center"}>
              {/* {this.state.totalSlides > 1 && (
                <p className="spacer">
                  <span>{`${this.state.currentSlide + 1}/${
                    this.state.totalSlides
                  }`}</span>
                </p>
              )} */}

              <div
                className="caption-text"
                dangerouslySetInnerHTML={{
                  __html: this.state.activeSlideCaption,
                }}
              />

              {/* {this.state.totalSlides > 1 && <p className="spacer"></p>} */}
            </Caption>
          )}
        </GalleryContainer>
      );
    }
  }
}

export default ProjectGallery;
