import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

// Styles
import { linkColor } from "../styles/colors";

// Components
import AdditionalContentGallery from "./additional-content-gallery";
import Line from "../misc/line";
import ImageCaption from "../text/image-caption";
import TextContainer from "../containers/text-container";
import TextBox from "../text/text-box";
import { Video } from "../video/video";

const ContextualImageWrapper = styled.div`
  width: 100%;

  max-width: 1200px;
  margin: 60px auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    margin: 30px auto;
  }
`;

const Container = styled.div`
  width: ${props => props.width}%;
  max-width: 600px;

  & > img {
    max-width: ${props => props.maxImageWidth}px;
    margin: 0 auto;
    padding: ${props => props.padding};

    @media (max-width: 500px) {
      padding: ${props => props.mobilePadding};
    }
  }
`;

const AdditionalContentWrapper = styled.div`
  order: 7;
`;

const TextFields = styled.div`
  font-feature-settings: "liga", "pnum", "lnum";

  & > .field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & h4,
    & p {
      margin: 0;
    }

    & h4 {
      width: 120px;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    & em {
      @media (max-width: 500px) {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      &:last-of-type {
        margin-bottom: 23px;
      }

      & p {
        font-size: 16px;
      }
    }

    @media (max-width: 500px) {
      margin-bottom: 0.5em;
    }
  }
`;

const CreditTitle = styled.div`
  height: 21px;

  // & h4::after {
  //   content: ":";
  //   display: inline;
  // }

  @media (max-width: 767px) {
    &:empty {
      height: 0;
    }
  }

  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }
`;

const AdditionalTextField = styled.div`
  & a:hover {
    color: ${linkColor};
  }
`;

const Tags = styled.p`
  & span {
    &::after {
      content: ", ";
    }
  }

  & span:last-of-type {
    &::after {
      content: "";
    }
  }

  &:hover > span {
    color: ${linkColor};
  }

  &:hover > span:hover {
    color: #000;
  }
`;

const TextLink = styled.div`
  & p {
    margin: 0;
  }
  & p::before {
    content: "→ ";
    display: inline;
    font-family: "Starling Arrow";
  }

  & a:hover {
    color: ${linkColor};
  }
`;

const AdditionalContent = ({ data, categories, client, outcome }) => {
  const content = data.map((data, contentIndex) => {
    if (data.slice_type === "contextual_images") {
      const contextualImages = data.items
        .filter(image => image.image.url !== null)
        .map((image, index) => (
          <Container
            key={`contextual_image_${index}`}
            width={100 / data.items.length}
            padding={
              data.items.length > 1 && index === 0 ? `0 15px 0 0` : `0 0 0 15px`
            }
            mobilePadding={
              data.items.length > 1 && index === 0 ? `0 5px 0 0` : `0 0 0 5px`
            }
            maxImageWidth={
              image.image.dimensions.width > image.image.dimensions.height
                ? 600
                : 375
            }
          >
            <img src={image.image.url} alt={image.image.alt} loading="lazy" />
            {image.image.alt && <ImageCaption text={image.image.alt} />}
          </Container>
        ));

      return (
        <div key={`contextual_image_${contentIndex}`}>
          <ContextualImageWrapper>
            <LazyLoad height={500}>{contextualImages}</LazyLoad>
          </ContextualImageWrapper>
        </div>
      );
    }

    if (data.slice_type === "line_break") {
      return (
        <Line
          showDesktop={`block`}
          showMobile={`block`}
          margin={`30px -20px`}
          mobileMargin={`30px -10px`}
          key={`contextual_line_break_${contentIndex}`}
        />
      );
    }

    if (data.slice_type === "text") {
      return (
        <TextContainer key={`contextual_text_${contentIndex}`}>
          <TextBox
            text={data.primary.text}
            margin={`30px auto`}
            mobileMargin={`30px 0`}
          />
          {data.items.map((link, index) => (
            <TextLink
              key={`link_${index}`}
              dangerouslySetInnerHTML={{ __html: link.link.html }}
            />
          ))}
        </TextContainer>
      );
    }

    if (data.slice_type === "credits") {
      return (
        <TextContainer key={`contextual_text_${contentIndex}`}>
          <TextFields>
            {data.primary.display_client === true && (
              <div className="field">
                <h4>
                  <em>Client</em> &nbsp;
                </h4>{" "}
                <Tags>{client}</Tags>
              </div>
            )}

            {data.primary.display_categories === true && (
              <div className="field">
                <h4>
                  <em>Category</em> &nbsp;
                </h4>{" "}
                <Tags>{categories}</Tags>
              </div>
            )}

            {data.primary.display_outcome === true && (
              <div className="field">
                <h4>
                  <em>Outcome</em> &nbsp;
                </h4>{" "}
                <Tags>{outcome}</Tags>
              </div>
            )}

            {data.items.map((item, index) => (
              <AdditionalTextField
                className="field"
                key={`additional_field_${index}`}
              >
                <em>
                  <CreditTitle
                    dangerouslySetInnerHTML={{
                      __html: item.credit_title.html,
                    }}
                  />
                </em>

                <div
                  dangerouslySetInnerHTML={{
                    __html: item.credit_text.html,
                  }}
                />
              </AdditionalTextField>
            ))}
          </TextFields>
        </TextContainer>
      );
    }

    if (data.slice_type === "footnotes") {
      return (
        <>
          <Line
            mobileWidth={`100vw`}
            margin={`30px -20px 30px -20px`}
            mobileMargin={`0 -10px 10px -10px`}
          />
          <TextContainer key={`contextual_footnotes_${contentIndex}`}>
            <TextBox
              text={data.primary.footnotes}
              margin={`0`}
              fontSize={`15px`}
              lineHeight={`21px`}
              mobileFontSize={`15px`}
              mobileLineHeight={`21px`}
            />
          </TextContainer>
        </>
      );
    }

    if (data.slice_type === "gallery") {
      return (
        <LazyLoad height={500} key={`contextual_gallery_${contentIndex}`}>
          <AdditionalContentGallery images={data.items} />
        </LazyLoad>
      );
    }

    if (data.slice_type === "video") {
      return (
        <LazyLoad height={500} key={`contextual_video_${contentIndex}`}>
          <Video
            video={data.primary.video}
            embed_url={data.primary.video.embed_url}
            playerId={`contextual_video_${contentIndex}`}
            caption={data.primary.video_caption.html}
          />
        </LazyLoad>
      );
    }
  });
  return <AdditionalContentWrapper>{content}</AdditionalContentWrapper>;
};

export default AdditionalContent;
