import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../images/carousel.css";

// Check is mobile
import isMobile from "ismobilejs";

// Components
import ThreeTwo from "../images/three-two";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  max-width: 1000px;

  margin: 60px auto;
  padding: 0;

  & .image-gallery {
    overflow: hidden;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  @media (max-width: 767px) {
    padding: 0;
    margin: 30px auto;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

  & img {
    object-fit: contain;
    padding: 0;
  }
`;

const SingleImageWrapper = styled.div``;

const Caption = styled.div`
  width: 100%;
  max-width: 1200px;

  padding: 10px 0 0 0;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};

  & p {
    margin: 0;

    & span {
      font-size: 15px;
    }
  }

  & p.spacer {
    width: 30px;
  }

  & .caption-text {
    max-width: calc(100% - 60px);
    display: block;
    position: relative;

    text-align: center;
  }
`;

class AdditionalContentGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allImages: [],
      activeSlide: null,
      totalSlides: 1,
      activeSlideCaption: null,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.images.length;
    const isMobileDevice = isMobile(window.navigator.userAgent).any ? 150 : 0;

    this.setState(
      {
        allImages: this.props.images,
        totalSlides: slideTotal,
        transitionTime: isMobileDevice,
      },
      () => {
        let activeSlideCaption = this.state.allImages[this.state.currentSlide]
          .image_caption.html;

        this.setState({
          activeSlideCaption: activeSlideCaption,
        });
      }
    );
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(
        {
          currentSlide: index,
        },
        () => {
          let activeSlideCaption = this.state.allImages[this.state.currentSlide]
            .image_caption.html;

          this.setState({
            activeSlideCaption: activeSlideCaption,
          });
        }
      );
    } else {
      let activeSlideCaption = this.state.allImages[this.state.currentSlide]
        .image_caption.html;

      this.setState({
        activeSlideCaption: activeSlideCaption,
      });
    }
  };

  render() {
    const galleryImages = this.props.images.map((image, index) => (
      <ImageContainer key={`gallery_image${index}`}>
        <ThreeTwo
          image={image.image.url}
          alt={image.image.alt}
          lazy={false}
          size={700}
          objectFit={
            image.image.dimensions.width > image.image.dimensions.height
              ? `cover`
              : `contain`
          }
        />
      </ImageContainer>
    ));

    return (
      <GalleryContainer>
        {this.state.totalSlides > 1 ? (
          <>
            <Carousel
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              className="image-gallery"
              selectedItem={this.state.currentSlide}
              onChange={this.updateCurrentSlide}
              transitionTime={this.state.transitionTime}
            >
              {galleryImages}
            </Carousel>
            <Caption
              justifyContent={
                this.state.totalSlides > 1 ? "space-between" : "center"
              }
            >
              {this.state.totalSlides > 1 && (
                <p className="spacer">
                  <span>{`${this.state.currentSlide + 1}/${
                    this.state.totalSlides
                  }`}</span>
                </p>
              )}

              <div
                className="caption-text"
                dangerouslySetInnerHTML={{
                  __html: this.state.activeSlideCaption,
                }}
              />

              {this.state.totalSlides > 1 && <p className="spacer"></p>}
            </Caption>
          </>
        ) : (
          <SingleImageWrapper>
            {galleryImages}
            {this.state.activeSlideCaption && (
              <Caption justifyContent={"center"}>
                <div
                  className="caption-text"
                  dangerouslySetInnerHTML={{
                    __html: this.state.activeSlideCaption,
                  }}
                />
              </Caption>
            )}
          </SingleImageWrapper>
        )}
      </GalleryContainer>
    );
  }
}

export default AdditionalContentGallery;
